import React from "react"
import { graphql } from "gatsby"
import { Container, Row, Col } from 'react-bootstrap';

import Layout from "../components/layout"
import SEO from "../components/seo"
import Events from '../components/views/events'

import Image from '../components/image';

import ShareButton from 'react-social-share-buttons'

export default function BlogPost({ data }) {
  const post = data.markdownRemark
  const url = data.site.siteMetadata.url
  return (
    <Layout>
      <style>{`
      .blog-content {
        font-size: 20px;
        font-weight: 400;
      }
      `}</style>
      <SEO title={post.frontmatter.title} description={post.excerpt} img={'/events/' + post.frontmatter.image} />
        <div className="page-container page-container-mt">
            <Container>
                <Row>
                    <Col>
                      <p className="text-muted">{post.frontmatter.date}</p>
                      <h1 className="mb-5">{post.frontmatter.title}</h1>
                      <div className="mb-5">
                          <Image src={`events/${post.frontmatter.image}`} alt={post.frontmatter.title} />
                      </div>
                      <div className="blog-content" dangerouslySetInnerHTML={{ __html: post.html }} />
                      <hr className="mb-5 mt-5" />
                      <div className="d-flex">
                          <div>Share: </div>
                          <div className="d-flex ml-2">
                          <ShareButton
                              compact
                              socialMedia={'facebook'}
                              url={url + post.fields.slug}
                              media={url + 'events/' + post.frontmatter.image}
                          />
                          <ShareButton
                              compact
                              socialMedia={'twitter'}
                              url={url + post.fields.slug}
                              media={url + 'events/' + post.frontmatter.image}
                          />
                          <ShareButton
                              compact
                              socialMedia={'pinterest'}
                              url={url + post.fields.slug}
                              media={url + 'events/' + post.frontmatter.image}
                          />
                          </div>
                      </div>
                    </Col>
                </Row>
            </Container>
        </div>
        <hr style={{marginBottom: 0}} />
      <Events />
  </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        image
        date(formatString: "DD MMMM, YYYY")
      }
      fields {
        slug
      }
    }
    site {
      siteMetadata {
        url
      }
    }
  }
`