import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from 'react-bootstrap';

import Image from '../image';

export default () => {
    const data = useStaticQuery(graphql`
        query {
            allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }, limit: 3) {
                edges {
                    node {
                        id
                        frontmatter {
                            title
                            image
                        }
                        fields {
                            slug
                        }
                    }
                }
            }
        }
    `)

    return (
        <>
        <style>{`
            .blog-image-wrapper {
                overflow: hidden;
                max-height: 210px;
            }
            .blog-image {
                transition: transform .2s;
            }
            .blog-image:hover {
                -ms-transform: scale(1.1); /* IE 9 */
                -webkit-transform: scale(1.1); /* Safari 3-8 */
                transform: scale(1.1);
            }
            .blog-title {
                letter-spacing: 4px;
                font-weight: 400;
            }
            .blog-link:hover {
                text-decoration: none;
            }
        `}</style>
        <section className="section">
            <Container>
                <Row>
                    <div className="col text-center">
                        <h2 className="section-title mb-5">NEWS + EVENTS</h2>
                    </div>
                </Row>
                <Row>
                    {data.allMarkdownRemark.edges.map(({ node }, index) => {
                        return (
                            <Col md={4} key={index}>
                                <Link to={node.fields.slug} className="blog-link">
                                    <div className="blog-image-wrapper mb-4">
                                        <Image className="blog-image" src={`events/${node.frontmatter.image}`} alt={node.frontmatter.title} />
                                    </div>
                                    <h3 className="blog-title">{node.frontmatter.title}</h3>
                                </Link>
                            </Col>
                        )
                    })}
                </Row>
            </Container>
        </section>
        </>
    )
}